var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"formConfirmaCiru",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Confirmar cirugía")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.cancela}},[_c('v-icon',[_vm._v("mdi-close-box")])],1)],1),_c('v-card-text',[_c('page-fecha',{attrs:{"label":"Fecha asignada","model":_vm.datos.fechaAsignadaFecha,"dense":true,"rules":[v => !!v || 'Requerido.']},on:{"update:model":function($event){return _vm.$set(_vm.datos, "fechaAsignadaFecha", $event)}}}),_c('div',{staticClass:"d-flex flex-row"},[_c('v-text-field',{attrs:{"label":"Hora asignada","dense":"","rounded":"","filled":"","rules":[
                            value => !!value || 'Requerido.',
                            value => /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(value)||'Formato de hora no válido',
                        ]},model:{value:(_vm.datos.fechaAsignadaHora),callback:function ($$v) {_vm.$set(_vm.datos, "fechaAsignadaHora", $$v)},expression:"datos.fechaAsignadaHora"}}),_c('v-menu',{attrs:{"bottom":"","left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.horaModal=true}}},on),[_c('v-icon',[_vm._v("mdi-clock")])],1)]}}]),model:{value:(_vm.horaAsignadaModal),callback:function ($$v) {_vm.horaAsignadaModal=$$v},expression:"horaAsignadaModal"}},[_c('v-time-picker',{attrs:{"format":"24hr"},on:{"change":function($event){_vm.horaModal=false}},model:{value:(_vm.datos.fechaAsignadaHora),callback:function ($$v) {_vm.$set(_vm.datos, "fechaAsignadaHora", $$v)},expression:"datos.fechaAsignadaHora"}})],1)],1),_c('v-select',{attrs:{"label":"Sala","rules":[v => !!v || 'Requerido.'],"items":[
                        {text:"Sala 1", value:1},
                        {text:"Sala 2", value:2},
                        {text:"Sala 3", value:3},
                        {text:"Sala 4", value:4},
                    ],"dense":"","rounded":"","filled":""},model:{value:(_vm.datos.sala),callback:function ($$v) {_vm.$set(_vm.datos, "sala", $$v)},expression:"datos.sala"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","loading":_vm.loadingGuarda,"disabled":!_vm.formValid},on:{"click":_vm.guarda}},[_vm._v(" Guardar ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }