<template>
    <v-dialog v-model="dialog" width=500>
        <v-card>
            <v-form ref="formConfirmaCiru" v-model="formValid">

                <v-toolbar color="primary" dark dense>
                    <v-toolbar-title>Confirmar cirugía</v-toolbar-title>
                    <v-spacer/>
                    <v-btn text @click="cancela">
                        <v-icon>mdi-close-box</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text>
                    <page-fecha
                        label='Fecha asignada'
                        :model.sync="datos.fechaAsignadaFecha"
                        :dense="true"
                        :rules="[v => !!v || 'Requerido.']"
                    ></page-fecha>


                    <div class="d-flex flex-row">
                        <v-text-field
                            label="Hora asignada"
                            v-model="datos.fechaAsignadaHora"
                            dense
                            rounded
                            filled
                            :rules="[
                                value => !!value || 'Requerido.',
                                value => /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(value)||'Formato de hora no válido',
                            ]"
                        />
                        <v-menu
                            v-model="horaAsignadaModal"
                            bottom
                            left
                            :close-on-content-click="false"
                        >

                            <template v-slot:activator="{ on }">
                                <v-btn icon @click="horaModal=true" v-on="on"><v-icon>mdi-clock</v-icon></v-btn>
                            </template>

                            <v-time-picker
                                v-model="datos.fechaAsignadaHora"
                                format="24hr"
                                @change="horaModal=false"
                            />
                        </v-menu>
                    </div>

                    <v-select
                        v-model="datos.sala"
                        label="Sala"
                        :rules="[v => !!v || 'Requerido.']"
                        :items='[
                            {text:"Sala 1", value:1},
                            {text:"Sala 2", value:2},
                            {text:"Sala 3", value:3},
                            {text:"Sala 4", value:4},
                        ]'
                        dense
                        rounded
                        filled
                    ></v-select>

                </v-card-text>

                <v-card-actions>
                    <v-spacer/>

                    <v-btn color="success" @click="guarda" :loading="loadingGuarda" :disabled="!formValid">
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    components:{
        'page-fecha':()=>import('@/components/com/SelectEditaFecha'),
    },
    props:{
        dialogModal: Boolean,
        cirugia:Object,
    },

    data:()=>({
        loadingGuarda:false,

        formValid:false,
        datos:{},

        horaAsignadaModal:false,
        horaModal:false,

        cirugias:[]
    }),

    methods:{
        async guarda(){
            this.loadingGuarda=true
            this.datos.fechaAsignada = this.datos.fechaAsignadaFecha+" "+this.datos.fechaAsignadaHora
            try{
                let req = await this.$http({
                    url: '/cirugia/create',
                    method:'POST',
                    params:this.datos,
                })
                this.loadingGuarda=false
                if (req.data.estado == "true"){
                    this.$emit("guardado")
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Confirmada',
                        text: 'Cirugía confirmada correctamente',
                    })
                    this.dialog=false
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            } catch(err){
                this.loadingGuarda=false
                this.$store.dispatch('security/revisaError',err)
            }
            this.dialog=false
        },
        cancela(){
            this.dialog=false
        },
    },

    computed:{
        dialog:{
            get(){return this.dialogModal},
            set(val){this.$emit('update:dialogModal',val)}
        }
    },
    watch:{
        dialog(v){
            if (!v){
                this.$refs.formConfirmaCiru.reset()
            }
            if(v){
                //this.datos = {...this.cirugia}
                this.datos.id = this.cirugia.id
                this.datos.fechaAsignadaFecha = this.cirugia.fechaSolicitada
            }
        }
    },
}
</script>

<style>

</style>